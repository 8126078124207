<template>
    <div class="contenedor-inventario">
        <div class="contenedor-sesiones-banco d-flex justify-content-around align-items-center">
            <div class="boton-sesiones" id="inventario" :style="`border-bottom:${marcaBotonInventario}px solid #110133;`" @click="cambiarSesion($event)">Inventario</div>
            <div class="boton-sesiones" id="entrada" :style="`border-bottom:${marcaBotonEntrada}px solid #110133;`" @click="cambiarSesion($event)">Entrada</div>
            <div class="boton-sesiones" id="nuevo" :style="`border-bottom:${marcaBotonNuevo}px solid #110133;`" @click="cambiarSesion($event)">Nuevo</div>
            <div class="boton-sesiones2" id="orden" :style="`border-bottom:${marcaBotonOrden}px solid #110133;`" @click="cambiarSesion($event)">Orden de requisición</div>
            <div class="boton-sesiones" id="exportar" :style="`border-bottom:${marcaBotonExportar}px solid #110133;`" @click="cambiarSesion($event)">Exportar</div>
        </div>
        <div class="contenedor-inventario-historial d-flex justify-content-start align-items-center flex-column" v-if="bulSesiones === 1">
            <div class="caja-filtro d-flex justify-content-center align-items-center" :style="`border-radius:10px 10px ${medidaBorde}px ${medidaBorde}px;`" @click="abrirFiltros">Filtros<div class="simboloIzquierda" :style="`transform: rotateZ(${izquierdaDesplegable}deg);`"></div><div class="simboloDerecha" :style="`transform: rotateZ(${derechaDesplegable}deg);`"></div></div>
            <div class="caja-filtros d-flex justify-content-around align-items-center flex-row flex-wrap" :style="`height:${alturaFiltro}px; padding-top:${paddingTop}px;`">
                <div class="col-2 margin-izquierda">
                    <label for="desde" class="label">Desde</label>
                    <input type="date" id="desde" class="input" v-model="desde" :max="hasta" @change="actualizarFiltros"/>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="hasta" class="label">Hasta</label>
                    <input type="date" id="hasta" class="input" v-model="hasta" :min="desde" @change="actualizarFiltros" />
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="precio" class="label">Precios</label>
                    <select name="" id="precio" class="input" v-model="precio" @keyup="actualizarFiltros">
                            <option value="" ></option>
                            <option v-for="precios in precioss" v-bind:key="precios.id" v-bind:value="precios">{{precios}}</option>
                    </select>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="producto" class="label">Producto</label>
                    <select name="" id="producto" class="input" v-model="producto" @keyup="actualizarFiltros">
                            <option value="" ></option>
                            <option v-for="productos in productoss" v-bind:key="productos.id" v-bind:value="productos">{{productos}}</option>
                    </select>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="grupoI" class="label">Grupos inventario</label>
                    <select name="" id="grupoI" class="input" v-model="grup" @keyup="actualizarFiltros">
                            <option value="" ></option>
                            <option v-for="grupes in grupess" v-bind:key="grupes.id" v-bind:value="provedores">{{provedores}}</option>
                    </select>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="marca" class="label">Marca</label>
                    <select name="" id="marca" class="input" v-model="marca" @keyup="actualizarFiltros">
                            <option value="" ></option>
                            <option v-for="marcas in marcass" v-bind:key="marcas.id" v-bind:value="marcas">{{marcas}}</option>
                    </select>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="ubicacion" class="label">Ubicación física</label>
                    <select name="" id="ubicacion" class="input" v-model="ubicacionFI" @keyup="actualizarFiltros">
                            <option value="" ></option>
                            <option v-for="ubicacionFs in ubicacionFss" v-bind:key="ubicacionFs.id" v-bind:value="ubicacionFs">{{cajas}}</option>
                    </select>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="cliente" class="label">Cliente</label>
                    <select name="" id="cliente" class="input" v-model="cliente" @keyup="actualizarFiltros">
                            <option value="" ></option>
                            <option v-for="clientes in clientess" v-bind:key="clientes.id" v-bind:value="clientes">{{clientes}}</option>
                    </select>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="provedor" class="label">Provedor</label>
                    <select name="" id="provedor" class="input" v-model="provedor" @keyup="actualizarFiltros">
                            <option value="" ></option>
                            <option v-for="provedors in provedorss" v-bind:key="provedors.id" v-bind:value="provedors">{{provedors}}</option>
                    </select>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="estatusInventario" class="label">Estatus</label>
                    <select name="" id="estatusInventario" class="input" v-model="estadosInventario" @keyup="actualizarFiltros">
                            <option value="" ></option>
                            <option v-for="estadosInventarios in estadosInventarioss" v-bind:key="estadosInventarios.id" v-bind:value="estadosInventarios">{{estadosInventarios}}</option>
                    </select>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="cantidadMinInventario" class="label">Cantidad mínima</label>
                    <input type="text" id="cantidadMinInventario" class="input" v-model="cantidadMinInventario" @change="actualizarFiltros"/>
                </div>
                <div class="col-2 margin-izquierda">
                    <label for="cantidadMaxInventario" class="label">Cantidad máxima</label>
                    <input type="text" id="cantidadMaxInventario" class="input" v-model="cantidadMaxInventario" @change="actualizarFiltros"/>
                </div>
            </div>
            <div class="caja-enunciado"></div>
        </div>
        <form class="contenedor-inventario-historial d-flex justify-content-center align-items-center flex-column" v-if="bulSesiones === 2">
            <div class="contenedor-formulario d-flex justify-content-center align-items-start flex-row" style="width:100%;">
                <div class="col-6 d-flex justify-content-center align-items-start flex-column">
                    <label for="monto" class="label2">Ubicación física</label>
                    <input type="text" id="monto" class="input2" v-model="ubicacionF"/>
                    <label for="tipoIva" class="label2">Tipo de I.V.A</label>
                    <input type="text" id="tipoIva" class="input2" v-model="tipoIva"/>
                </div>
                <div class="col-6 d-flex justify-content-center align-items-start flex-column">
                    <label for="nombreAr" class="label2">Nombre del articulo</label>
                    <select name="" id="nombreAr" class="input2" v-model="nombreI">
                            <option value="" ></option>
                            <option v-for="nombreA in nombreArticulos" v-bind:key="nombreA.id" v-bind:value="nombreA">{{nombreA}}</option>
                    </select>
                    <label for="bulto" class="label2">Cantidad / Bulto</label>
                    <input type="text" id="bulto" class="input2" v-model="bulto"/>
                </div>
            </div>
            <div class="col-6 d-flex flex-row justify-content-center align-items-center mb-3">
                <div class="entrada d-flex flex-row justify-content-center align-items-center">
                    <div class="checkbox d-flex justify-content-center align-items-center" @click="selectCheckbox($event)"><div v-if="caja1 === true" class="caja"></div></div>
                    <p class="texto-check">Entrada</p>
                </div>
                <div class="salida d-flex flex-row justify-content-center align-items-center">
                    <div class="checkbox2 d-flex justify-content-center align-items-center" @click="selectCheckbox($event)"><div v-if="caja2 === true" class="caja2"></div></div>
                    <p class="texto-check">Salida</p>
                </div>
            </div>
            <div class="col-6 d-flex justify-content-center align-items-start flex-column" v-if="caja1 === true">
                <label for="provedorInventario" class="label2">Provedor</label>
                <select name="" id="provedorInventario" class="input2" v-model="estado" @keyup="provedorI">
                        <option value="" ></option>
                        <option v-for="provedor in provedorIs" v-bind:key="provedor.id" v-bind:value="provedor">{{provedor}}</option>
                </select>
            </div>
            <div class="col-6 d-flex justify-content-center align-items-start flex-column" v-if="caja2 === true">
                <label for="clienteInventario" class="label2">Cliente</label>
                <select name="" id="clienteInventario" class="input2" v-model="estado" @keyup="clienteI">
                        <option value="" ></option>
                        <option v-for="cliente in clienteIs" v-bind:key="cliente.id" v-bind:value="cliente">{{cliente}}</option>
                </select>
            </div>
            <div class="contenedor-botones d-flex justify-content-around align-items-center">
                <button class="button-exportar d-flex justify-content-center align-items-center">Agregar</button>
                <button class="button-exportar d-flex justify-content-center align-items-center">Borar</button>
            </div>
        </form>
        <div class="contenedor-inventario-historial2 d-flex justify-content-start align-items-center flex-column" v-if="bulSesiones === 3">
            <div class="contenedor-formulario d-flex justify-content-center align-items-start flex-row" style="width:100%;">
                <div class="col-6 d-flex justify-content-center align-items-start flex-column">
                    <label for="condigoContableInventario" class="label2">Código contable</label>
                    <input type="text" id="condigoContableInventario" class="input2" v-model="codigoCI"/>
                    <label for="nombreArticulo" class="label2">Nombre</label>
                    <input type="text" id="nombreArticulo" class="input2" v-model="nombreAr"/>
                    <label for="grupoI" class="label2">Grupos inventario</label>
                    <input type="text" id="grupoI" class="input2" v-model="grupoI"/>
                    <label for="marca" class="label2">Marca</label>
                    <input type="text" id="marca" class="input2" v-model="marcaI"/>
                    <label for="unidadDeMedida" class="label2">Unidad de medida</label>
                    <input type="text" id="unidadDeMedida" class="input2" v-model="unidadM"/>
                    <label for="alturaA" class="label2">Altura</label>
                    <input type="text" id="alturaA" class="input2" v-model="alturaI"/>
                    <label for="pesoA" class="label2">Peso</label>
                    <input type="text" id="pesoA" class="input2" v-model="pesoI"/>
                    <label for="usos" class="label2">Uso</label>
                    <select name="" id="usos" class="input2" v-model="estado" @keyup="usosI">
                            <option value="" ></option>
                            <option v-for="usos in usosInventario" v-bind:key="usos.id" v-bind:value="usos">{{usos}}</option>
                    </select>
                </div>
                <div class="col-6 d-flex justify-content-center align-items-start flex-column">
                    <label for="peciosA" class="label2">Precios</label>
                    <select name="" id="peciosA" class="input2" v-model="estado" @keyup="preciosInventario">
                            <option value="" ></option>
                            <option v-for="precios in preciossI" v-bind:key="precios.id" v-bind:value="precios">{{precios}}</option>
                    </select>
                    <label for="minimo" class="label2">Alerta de existencia minima</label>
                    <input type="text" id="minimo" class="input2" v-model="alertaMin"/>
                    <label for="maximo" class="label2">Alerta de existencia maxima</label>
                    <input type="text" id="maximo" class="input2" v-model="alertaMax"/>
                    <label for="fechaCreacion" class="label2">Fecha de creación</label>
                    <input type="date" id="fechaCreacion" class="input2" v-model="fechaCreacion"/>
                    <label for="statusI" class="label2">Estatus</label>
                    <select name="" id="statusI" class="input2" v-model="estado" @keyup="estatusInventario">
                            <option value="" ></option>
                            <option v-for="estatus in estatusInventarios" v-bind:key="estatus.id" v-bind:value="estatus">{{estatus}}</option>
                    </select>
                    <label for="anchoArticulo" class="label2">Ancho</label>
                    <input type="text" id="anchoArticulo" class="input2" v-model="anchoArticulo"/>
                    <label for="profundidadArticulo" class="label2">Profundidad</label>
                    <input type="text" id="profundidadArticulo" class="input2" v-model="profundidadArticulo"/>
                    <label for="compuestoArticulo" class="label2">Compuesto</label>
                    <input type="text" id="compuestoArticulo" class="input2" v-model="compuestoArticulo"/>
                </div>
            </div>
            <div class="col-10 d-flex flex-row justify-content-center align-items-center mb-3">
                <div class="cajaCheckMovimiento d-flex flex-row justify-content-center align-items-center">
                    <div class="checkboxMovimiento d-flex justify-content-center align-items-center" @click="selectCheckbox2($event)"><div v-if="cajaMovimiento === true" class="cajaMovimiento"></div></div>
                    <p class="texto-check">Crear movimiento también</p>
                </div>
            </div>
            <div class="col-6 d-flex flex-row justify-content-center align-items-center mb-3" v-if="cajaMovimiento === true">
                <div class="entrada d-flex flex-row justify-content-center align-items-center">
                    <div class="checkbox3 d-flex justify-content-center align-items-center" @click="selectCheckbox($event)"><div v-if="caja3 === true" class="caja"></div></div>
                    <p class="texto-check">Entrada</p>
                </div>
                <div class="salida d-flex flex-row justify-content-center align-items-center">
                    <div class="checkbox4 d-flex justify-content-center align-items-center" @click="selectCheckbox($event)"><div v-if="caja4 === true" class="caja2"></div></div>
                    <p class="texto-check">Salida</p>
                </div>
            </div>
            <div class="contenedor-formulario d-flex justify-content-center align-items-start flex-row" style="width:100%;" v-if="cajaMovimiento === true">
                <div class="col-6 d-flex justify-content-center align-items-start flex-column">
                    <label for="monto" class="label2">Ubicación física</label>
                    <input type="text" id="monto" class="input2" v-model="ubicacionF2"/>
                    <label for="tipoIva" class="label2">Tipo de I.V.A</label>
                    <input type="text" id="tipoIva" class="input2" v-model="tipoIva2"/>
                </div>
                <div class="col-6 d-flex justify-content-center align-items-start flex-column">
                    <label for="provedorInventario" class="label2" v-if="caja3 === true">Provedor</label>
                    <select name="" id="provedorInventario" class="input2" v-model="estado" @keyup="provedorI2" v-if="caja3 === true">
                            <option value="" ></option>
                            <option v-for="provedor in provedorIs2" v-bind:key="provedor.id" v-bind:value="provedor">{{provedor}}</option>
                    </select>
                    <label for="clienteInventario" class="label2" v-if="caja4 === true">Cliente</label>
                    <select name="" id="clienteInventario" class="input2" v-model="estado" @keyup="clienteI2" v-if="caja4 === true">
                            <option value="" ></option>
                            <option v-for="cliente in clienteIs2" v-bind:key="cliente.id" v-bind:value="cliente">{{cliente}}</option>
                    </select>
                    <label for="bulto" class="label2">Cantidad / Bulto</label>
                    <input type="text" id="bulto" class="input2" v-model="bulto2"/>
                </div>
            </div>
            <div class="contenedor-botones d-flex justify-content-around align-items-center">
                <button class="button-exportar d-flex justify-content-center align-items-center">Crear</button>
                <button class="button-exportar d-flex justify-content-center align-items-center">Borar</button>
            </div>
        </div>
        <div class="contenedor-inventario-historial3 d-flex justify-content-start align-items-center flex-column" v-if="bulSesiones === 4">
            <div class="col-6">
                <label for="filtradoInventario" class="label3">Filtrado inventario</label>
                <input type="text" id="filtradoInventario" class="input3" v-model="filtradoInventario" />
            </div>
            <div class="col-12 d-flex flex-nowrap justify-content-around align-items-center">
                <div class="cuadro-filtro-seleccionado" v-for="seleccion in seleccionFiltro" v-bind:key="seleccion.id" v-bind:value="seleccion">{{seleccion}}</div>
            </div>
            <div class="col-6">
                <label for="formato" class="label3">Formato</label>
                <input type="text" id="formato" class="input3" v-model="formato" />
            </div>
            <p class="exportar-informacion">Se va a exportar lo anteriormente filtrado en el apartado de inventario, puedes guardar tambien estos informes</p>
            <div class="contenedor-botones d-flex justify-content-around align-items-center">
                <button class="button-exportar d-flex justify-content-center align-items-center">Descargar</button>
                <button class="button-exportar d-flex justify-content-center align-items-center">Borar</button>
            </div>
        </div>
        <div class="contenedor-inventario-historial d-flex justify-content-start align-items-center flex-column" v-if="bulSesiones === 5">
            
        </div>
    </div>
</template>

<script>
    import Footer from "../../../components/Footer.vue"
    import {mapState, mapActions} from "vuex"
    import axios from 'axios'

    export default {
        name: "App",
        components: {
            Footer
        },
        data(){
            return{
                //fondo
                alturaFondoDegradado: `calc(100% + 290px)`,

                //control sesiones
                bulSesiones: 1,
                marcaBotonInventario: 4,
                marcaBotonEntrada: 0,
                marcaBotonNuevo: 0,
                marcaBotonExportar: 0,
                marcaBotonOrden: 0,

                //deplegable filtro y variables filtro
                izquierdaDesplegable: 50,
                derechaDesplegable: -50,
                medidaBorde:10,
                alturaFiltro: 0,
                paddingTop: 0,
                desde:'',
                hasta:'',
                precio: '',
                precioss: this.precioss = [],
                producto: '',
                productoss: this.productoss = [],
                grup: '',
                grupess: this.grupess = [],
                marca: '',
                marcass: this.marcass = [],
                ubicacionFI: '',
                ubicacionFss: this.ubicacionF = [],
                cliente: '',
                clientess: this.clientess = [],
                provedor: '',
                provedorss: this.provedorss = [],
                estadosInventario: '',
                estadosInventarioss: this.estadosInventario = [],
                unidadInventario: '',
                unidadInventarioss: this.unidadInventarioss = [],
                cantidadMinInventario: '',
                cantidadMaxInventario: '',

                //interfax de entrada al inventario
                caja1: false,
                caja2:false,
                ubicacionF: '',
                tipoIva: '',
                nombreI: '',
                nombreArticulos: this.nombreArticulos = [],
                bulto: '',
                provedorI: '',
                provedorIs: this.provedorIs = [],
                clienteI: '',
                cleinteIs: this.cleinteIs = [],

                //interfaz de exportar
                filtradoInventario: '',
                seleccionFiltro: this.seleccionFiltro = [],
                formato: '',

                //interfaz nuevo inventario
                caja3: false,
                caja4:false,
                cajaMovimiento: false,
                codigoCI: '',
                nombreAr: '',
                grupoI: '',
                marcaI: '',
                unidadM: '',
                alturaI: '',
                pesoI: '',            
                usosI: '',
                usosInventario: this.usosInventario = [],
                preciosInventario: '',
                preciossI: this.preciossI = [],
                alertaMin: '',
                alertaMax: '',
                fechaCreacion: '',
                estatusInventario: '',
                estatusInventarios: this.estatusInventarios = [],
                anchoArticulo: '',
                profundidadArticulo: '',
                compuestoArticulo: '',
                bulto2: '',
                provedorI2: '',
                provedorIs2: this.provedorIs2 = [],
                clienteI2: '',
                cleinteIs2: this.cleinteIs2 = [],
                tipoIva2: '',
                ubicacionF2: '',
            }
        },
        methods:{

            //funcion encargada del checkbox salida/entrada

            selectCheckbox(e){
                if(e.target.className === 'checkbox d-flex justify-content-center align-items-center'){
                    if(this.caja1 === false){
                        this.caja1 = true
                        this.caja2 = false
                    }
                }
                if(e.target.className === 'checkbox2 d-flex justify-content-center align-items-center'){
                    if(this.caja2 === false){
                        this.caja2 = true
                        this.caja1 = false
                    }
                }
                if(e.target.className === 'checkbox3 d-flex justify-content-center align-items-center'){
                    if(this.caja3 === false){
                        this.caja3 = true
                        this.caja4 = false
                    }
                }
                if(e.target.className === 'checkbox4 d-flex justify-content-center align-items-center'){
                    if(this.caja4 === false){
                        this.caja4 = true
                        this.caja3 = false
                    }
                }
            },

            //funcion encargada del checkbox movimiento

            selectCheckbox2(e){
                if(e.target.className === 'checkboxMovimiento d-flex justify-content-center align-items-center'){
                    if(this.cajaMovimiento === false){
                        this.cajaMovimiento = true
                    }else{
                        this.cajaMovimiento = false
                    }
                }else{
                    if(this.cajaMovimiento === false){
                        this.cajaMovimiento = true
                    }else{
                        this.cajaMovimiento = false
                    }
                }
            },

            //cambiar sesion de banco

            cambiarSesion(e){
                if(e.target.id === 'inventario'){
                    this.bulSesiones = 1
                    this.marcaBotonInventario = 4
                    this.marcaBotonEntrada = 0
                    this.marcaBotonNuevo = 0
                    this.marcaBotonExportar = 0
                    this.marcaBotonOrden = 0
                    this.alturaFondoDegradado = `calc(100% + 290px)`
                }
                if(e.target.id === 'entrada'){
                    this.bulSesiones = 2
                    this.marcaBotonInventario = 0
                    this.marcaBotonEntrada = 4
                    this.marcaBotonNuevo = 0
                    this.marcaBotonExportar = 0
                    this.marcaBotonOrden = 0
                    this.alturaFondoDegradado = `calc(100% + 290px)`
                }
                if(e.target.id === 'nuevo'){
                    this.bulSesiones = 3
                    this.marcaBotonInventario = 0
                    this.marcaBotonEntrada = 0
                    this.marcaBotonNuevo = 4
                    this.marcaBotonExportar = 0
                    this.marcaBotonOrden = 0
                    this.alturaFondoDegradado = `calc(100% + 1190px)`
                }
                if(e.target.id === 'exportar'){
                    this.bulSesiones = 4
                    this.marcaBotonInventario = 0
                    this.marcaBotonEntrada = 0
                    this.marcaBotonNuevo = 0
                    this.marcaBotonExportar = 4
                    this.marcaBotonOrden = 0
                    this.alturaFondoDegradado = `calc(100% + 290px)`
                }
                if(e.target.id === 'orden'){
                    this.bulSesiones = 5
                    this.marcaBotonInventario = 0
                    this.marcaBotonEntrada = 0
                    this.marcaBotonNuevo = 0
                    this.marcaBotonExportar = 0
                    this.marcaBotonOrden = 4
                    this.alturaFondoDegradado = `calc(100% + 290px)`
                }
            },

            //desplegar menu desplegable

            desplegarMenuCochinito(){
                if(this.cuadroCochinito === 0){
                    this.cuadroCochinito = -20
                    this.anchoContenedor = 100
                    this.marginLeftContenedor = 0
                }else{
                    this.cuadroCochinito = 0
                    this.anchoContenedor = 80
                    this.marginLeftContenedor = 20
                }
            },

            //desplegar filtros de espacio inicial de cuenta

            abrirFiltros(){
                if(this.alturaFiltro === 0){
                    this.izquierdaDesplegable = -50
                    this.derechaDesplegable = 50
                    this.medidaBorde = 0
                    this.alturaFiltro = 250
                    this.paddingTop = 20
                }else{
                    this.izquierdaDesplegable = 50
                    this.derechaDesplegable = -50
                    this.medidaBorde = 10
                    this.alturaFiltro = 0
                    this.paddingTop = 0
                }
            },

            //actualizar filtros para mostrar en exportar

            actualizarFiltros(){
                this.seleccionFiltro = []
                if(this.desde !== ''){
                    this.seleccionFiltro.push(this.desde)
                }
                if(this.hasta !== ''){
                    this.seleccionFiltro.push(this.hasta)
                }
                if(this.precio !== ''){
                    this.seleccionFiltro.push(this.precio)
                }
                if(this.producto !== ''){
                    this.seleccionFiltro.push(this.producto)
                }
                if(this.grup !== ''){
                    this.seleccionFiltro.push(this.grup)
                }
                if(this.marca !== ''){
                    this.seleccionFiltro.push(this.marca)
                }
                if(this.ubicacionFI !== ''){
                    this.seleccionFiltro.push(this.ubicacionFI)
                }
                if(this.cliente !== ''){
                    this.seleccionFiltro.push(this.cliente)
                }
                if(this.provedor !== ''){
                    this.seleccionFiltro.push(this.provedor)
                }
                if(this.estadosInventario !== ''){
                    this.seleccionFiltro.push(this.estadosInventario)
                }
                if(this.unidadInventario !== ''){
                    this.seleccionFiltro.push(this.unidadInventario)
                }
                if(this.cantidadMinInventario !== ''){
                    this.seleccionFiltro.push(this.cantidadMinInventario)
                }
                if(this.cantidadMaxInventario !== ''){
                    this.seleccionFiltro.push(this.cantidadMaxInventario)
                }
            },

        },
        computed:{
        ...mapState(["token", "server"]),
        }
    }
</script>

<style scoped>
    .fondo-degradado-recover{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        background: linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%), linear-gradient(248.49deg, rgba(255, 255, 255, 0) 41.48%, #297F87 100.24%);
    }
    .contenedor-sesiones-banco{
        width: 100%;
        margin-top:80px;
        height: 100px;
    }
    .boton-sesiones,.boton-sesiones2{
        width: 15%;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
        font-size: 26px;
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.15) 100%);
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 15px white;
        padding-top:15px ;
        padding-bottom:15px ;
        border-radius: 16px;
        cursor: pointer;
        border-bottom:0px solid #110133;
        transition: 0.2s ease-in-out;
    }
    .boton-sesiones2{
        width: 20%;
    }
    .contenedor-inventario{
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
    }
    .entrada,.salida,.cajaCheckMovimiento{
        width: 40%;
    }
    .texto-check{
        margin-top: 6px;
    }
    .contenedor-botones{
        margin-top:25px;
        width: 100%;
    }
    .button-exportar{
        height: 40px;
        border:none;
        border-radius:10px;
        background: #297F87;
        color: white;
        text-align: center;
        font-family: Rubik;
        font-size:20px ;
        width: 30%;
        margin-left: 0%;
    }
    .contenedor-inventario-historial,.contenedor-inventario-historial2,.contenedor-inventario-historial3{
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.15) 100%);
        border-radius: 16px;
        padding: 10px;
        height: 600px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 15px white;
        width: 90%;
        margin-left:5%;
        transition: all 1s;
        backdrop-filter: blur(2px);
        margin-top: 20px;
    }
    .contenedor-inventario-historial2{
        height: 1500px;
        padding-top: 100px;
    }
    .contenedor-inventario-historial3{
        padding-top: 100px;
    }
    .caja-filtro{
        background:rgba(196,196,196,0.25);
        width: 100px;
        height: 30px;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
        font-size: 15px;
        position: absolute;
        left: 20px;
        top: 20px;
        cursor:pointer;
        transition: all 1s;
    }
    .caja-filtros{
        width: calc(100% - 20px);
        background:rgba(196,196,196,0.25);
        height: 100px;
        margin-top:40px;
        border-radius:0px 10px 10px 10px;
        transition: all 1s;
        overflow: hidden;
    }
    .simboloIzquierda{
        height: 1.5px;
        width: 7.5px;
        background: black;
        margin-left: 7.5px;
        transition: all 1s;
    }
    .simboloDerecha{
        height: 1.5px;
        width: 7.5px;
        background: black;
        margin-left: -3.5px;
        transition: all 1s;
    }
    .margin-izquierda{
        margin-left:4%;
    }
    .input,.input2,.input3{
        border-radius:10px;
        border: #6464646b 2px solid;
        width: 100%;
        padding-left: 10px;
        padding-top: 2px;
        padding-bottom: 3px;
        height: 30px;
        background: white;
        margin-left:0%;
        margin-bottom:25px;
        font-size:16px ;
    }
    .input2{
        margin-bottom: 50px;
        width: 70%;
        margin-left:15%;
    }
    .input3{
        margin-bottom: 40px;
    }
    .label,.label2{
        font-family: Rubik;
        font-weight:bold ;
    }
    .label2{
        margin-left:15%;
    }
    .caja-enunciado{
        width: calc(100% - 20px);
        background:rgba(196,196,196,0.25);
        height: 50px;
        border-radius:10px;
        transition: all 1s;
        margin-top:15px;
    }
    .checkbox,.checkbox2,.checkbox3,.checkbox4,.checkboxMovimiento{
        width: 20px;
        height: 20px;
        border-radius:50%;
        border: #6464646b 2px solid;
        margin-right: 15px;
        margin-top:-8px;
        background: white;
    }
    .caja,.caja2,.cajaMovimiento{
        width: 12px;
        height: 12px;
        background: #297F87;
        border-radius:2px;
        opacity: 1;
        border-radius:50%;
    }
    .cajaMovimiento,.checkboxMovimiento{
        border-radius: 0px;
    }
    .exportar-informacion{
        width: 60%;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-size:18px ;
    }
    .cuadro-filtro-seleccionado{
        background: linear-gradient(153.24deg, rgba(0, 145, 142, 0.4) 9.04%, rgba(255, 255, 255, 0) 100%);
        width: 130px;
        padding:5px;
        color: black;
        text-align: center;
        font-family: Rubik;
        font-size:18px ;
        border-radius:10px ;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.2), inset 0 0 5px white;
        margin-top:10px;
        margin-bottom: 25px;
    }
</style>